import React from 'react'
import { Line } from 'react-chartjs-2'

import { transformMutlipleDaysData } from '../../../../core/utils'
import {
  getUserNameFromCompanyArray,
  getUserName,
} from '../../../../core/utils'
import './ChartJsInit.js'

export const LineChart = ({
  data,
  userIdArray,
  type,
  companyUsers,
  currentUser,
}) => {
  console.log('type', type)
  const lineData = transformMutlipleDaysData(data)

  // Prepare datasets for Chart.js
  const datasets = userIdArray
    .map((userId, index) => {
      let username = getUserNameFromCompanyArray(userId, companyUsers)
      if (username.length === 0) {
        username = getUserName(currentUser)
      }

      let color = '#F3A53F'
      if (index === 1) {
        color = '#9878FF'
      } else if (index > 1) {
        color = `#${Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, '0')}`
      }

      return [
        {
          label: `${username} (Max)`,
          data: lineData.map((item) => item[`${userId}_max`] || null),
          borderColor: color,
          borderWidth: 1,
          backgroundColor: color,
          tension: 0.4,
          fill: false,
          pointRadius: 3,
          pointBackgroundColor: '#fff',
          pointBorderColor: color,
        },
        {
          label: `${username} (Min)`,
          data: lineData.map((item) => item[`${userId}_min`] || null),
          borderColor: color,
          borderWidth: 1,
          backgroundColor: color,
          tension: 0.4,
          fill: false,
          pointRadius: 3,
          pointBackgroundColor: '#fff',
          pointBorderColor: color,
        },
      ]
    })
    .flat()

  const chartData = {
    labels: lineData.map((item) => item.time),
    datasets,
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        cornerRadius: 10,
        boxPadding: 5,
        borderColor: '#E8E7E7',
        borderWidth: 1,
        bodySpacing: 5,
        padding: 10,
        boxWidth: 8,
        boxHeight: 8,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x', // Enable horizontal panning
        },
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming with touch gestures
          },
          mode: 'x', // Enable horizontal zooming
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 10,
          },
          callback: function (value) {
            return this.getLabelForValue(value)
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  }

  return (
    <div style={{ width: '100%', height: '250px' }}>
      <Line data={chartData} options={options} />
    </div>
  )
}
