import { useContext } from 'react'
import { AuthContext } from '../../../hooks/AuthContext'
import { Card } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import './ChartJs/ChartJsInit.js'

import {
  sortAndConvertSleepDataByTime,
  getUserColor,
  stageMapping,
  getUserNameFromCompanyArray,
  getUserName,
} from '../../../core/utils'

const SleepDataCard = ({ sleepData, companyUsers }) => {
  const { user: currentUser } = useContext(AuthContext)

  const chartData = []
  const userLines = []

  if (sleepData) {
    Object.entries(sleepData).forEach(([userId, userData], index) => {
      if (userData && userData.length > 0) {
        const sortedData = sortAndConvertSleepDataByTime(userData)

        sortedData.forEach((entry) => {
          const sleep = {
            time: entry.time,
            orignalTime: entry.orignalStartTime,
          }
          sleep[userId] = entry.stage
          chartData.push(sleep)
        })

        userLines.push({ userId, color: getUserColor(index) })
      }
    })
  }

  chartData.sort((a, b) => new Date(a.orignalTime) - new Date(b.orignalTime))

  // Prepare datasets for Chart.js
  const datasets = userLines.map((user) => {
    let username = getUserNameFromCompanyArray(user.userId, companyUsers)
    if (!username) {
      username = getUserName(currentUser)
    }

    return {
      label: username,
      data: chartData.map((entry) =>
        entry[user.userId] !== undefined ? entry[user.userId] : null,
      ),
      borderColor: user.color,
      backgroundColor: `rgba(${parseInt(user.color.slice(1, 3), 16)}, ${parseInt(
        user.color.slice(3, 5),
        16,
      )}, ${parseInt(user.color.slice(5, 7), 16)}, 0.2)`,
      fill: false,
      tension: 0,
      spanGaps: true,
      borderWidth: 2,
      pointBackgroundColor: '#fff',
      stepped: true,
    }
  })

  const data = {
    labels: chartData.map((entry) => entry.time),
    datasets,
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw
            return (
              Object.keys(stageMapping).find(
                (key) => stageMapping[key] === value,
              ) || ''
            )
          },
          title: (context) => `Time: ${context[0].label}`,
        },
        mode: 'index',
        intersect: false,
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        cornerRadius: 10,
        boxPadding: 5,
        borderColor: '#E8E7E7',
        borderWidth: 1,
        bodySpacing: 5,
        padding: 10,
        boxWidth: 8,
        boxHeight: 8,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x', // Enable horizontal panning
        },
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming with touch gestures
          },
          mode: 'x', // Enable horizontal zooming
        },
      },
    },
    scales: {
      x: {
        title: { display: true, text: 'Time' },
        ticks: { autoSkip: true, maxTicksLimit: 10 },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: (value) =>
            Object.keys(stageMapping).find(
              (key) => stageMapping[key] === value,
            ) || '',
        },
      },
    },
  }

  return (
    <Card className="healthCardContainer">
      <Card.Body>
        <Card.Title className="healthCardTitle">Sleep Data Overview</Card.Title>
        {chartData.length > 0 ? (
          <div style={{ width: '100%', height: '250px' }}>
            <Line data={data} options={options} />
          </div>
        ) : (
          <div className="noData">No sleep data available</div>
        )}
      </Card.Body>
    </Card>
  )
}

export default SleepDataCard
