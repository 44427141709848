import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  getUserNameFromCompanyArray,
  getUserName,
} from '../../../../core/utils'
import './ChartJsInit.js'

export const AreaChart = ({
  data,
  userIdArray,
  type,
  companyUsers,
  currentUser,
}) => {
  console.log('type', type)
  // Generate datasets for each user
  const datasets = userIdArray.map((userId, index) => {
    let username = getUserNameFromCompanyArray(userId, companyUsers)
    if (username.length === 0) {
      username = getUserName(currentUser)
    }

    // Generate a unique color for each user
    let color = '#F3A53F'
    if (index === 1) {
      color = '#9878FF'
    } else if (index > 1) {
      color = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`
    }

    return {
      label: username,
      data: data.map((item) => item[userId] || null),
      borderColor: color,
      backgroundColor: `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.2)`,
      fill: true, // Enable area filling
      tension: 0.4,
      pointRadius: 0, // No points displayed on the chart
      borderWidth: 1,
      spanGaps: true,
    }
  })

  const chartData = {
    labels: data.map((item) => item.time),
    datasets,
  }

  const options = {
    responsive: true,

    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        cornerRadius: 10,
        boxPadding: 5,
        borderColor: '#E8E7E7',
        borderWidth: 1,
        bodySpacing: 5,
        padding: 10,
        boxWidth: 8,
        boxHeight: 8,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x', // Enable horizontal panning
        },
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming with touch gestures
          },
          mode: 'x', // Enable horizontal zooming
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  }

  return (
    <div style={{ width: '100%', height: '250px' }}>
      <Line data={chartData} options={options} />
    </div>
  )
}
